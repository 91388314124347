import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import CopyButton from '~/components/elements/CopyButton'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useIsMobile from '~/hooks/useIsMobile'
import useIsVisible from '~/hooks/useIsVisible'

export default function ContentExpertCTA({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const isMobile = useIsMobile()
  const [ref, isVisible] = useIsVisible()

  return (
    <section data-component="ContentExpertCTA" className="section" id={fields?.sectionId} ref={ref}>
      <div className="container max-w-screen-md mx-auto">
        <div className="sm:grid flex flex-col items-center grid-cols-2 lg:-mx-10">
          <div>
            {fields?.title && isMobile && <h2 className="title--medium">{fields?.title}</h2>}
            {fields?.description && isMobile && <Content className="content my-6">{fields?.description}</Content>}

            <div
              className={clsx(
                'bg-site-base text-white sm:w-[281px] w-[225px]  sm:h-[360px] h-[288px] relative flex flex-col mx-auto',
                isVisible && 'animate-fade-right animate-ease-in-out',
                isVisible ? 'opacity-100' : 'opacity-0'
              )}
            >
              <LossePlaatjie className="absolute left-0 top-0 object-cover w-full h-full'" src={fields?.relation?.featuredImage?.node} />

              <div className="relative z-10 flex flex-col p-6 justify-end mt-auto to-site-base bg-gradient-to-b from-site-base/0">
                <strong className="sm:text-2xl text-lg">{fields?.relation?.title}</strong>
                <span className="text-site-accent">{fields?.relation?.recap?.function}</span>
              </div>
            </div>
          </div>

          <div
            className={clsx(
              'py-10 w-full',
              isVisible && 'animate-fade-up animate-ease-in-out animate-delay-200',
              isVisible ? 'opacity-100' : 'opacity-0'
            )}
          >
            {fields?.title && !isMobile && <h2 className="title--medium">{fields?.title}</h2>}
            {fields?.description && !isMobile && <Content className="content my-6">{fields?.description}</Content>}

            {fields?.relation?.recap?.whatsapp?.title && (
              <LosseLink to={fields?.relation?.recap?.whatsapp?.url} className="btn--accent w-full">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
                  <path
                    fill="#fff"
                    d="M4.327 20.113l1.186-4.198a8.382 8.382 0 01-1.16-4.27c0-4.647 3.783-8.427 8.434-8.427s8.434 3.78 8.434 8.427c0 4.648-3.783 8.428-8.434 8.428A8.469 8.469 0 018.66 19l-4.333 1.113zm4.558-2.649l.258.16a6.973 6.973 0 003.644 1.019c3.863 0 7.003-3.138 7.003-6.998 0-3.86-3.14-6.997-7.003-6.997-3.862 0-7.003 3.138-7.003 6.997 0 1.344.385 2.648 1.107 3.774l.172.271-.682 2.417 2.504-.643z"
                  ></path>
                  <path
                    fill="#EAEAEB"
                    d="M10.285 7.23l-.575-.036a.678.678 0 00-.49.178c-.278.259-.72.756-.859 1.408-.2.97.11 2.157.92 3.343s2.326 3.083 5.004 3.891c.865.26 1.543.082 2.062-.274.416-.282.7-.741.803-1.253l.09-.46a.33.33 0 00-.166-.355l-1.924-.949a.288.288 0 00-.36.096l-.761 1.053a.196.196 0 01-.242.074c-.52-.193-2.263-.978-3.218-2.95-.042-.089-.035-.193.027-.26l.727-.896a.339.339 0 00.048-.334l-.83-2.09a.303.303 0 00-.256-.185z"
                  ></path>
                </svg>

                {fields?.relation?.recap?.whatsapp?.title}
              </LosseLink>
            )}

            <div className="grid grid-cols-2 gap-4 mt-5">
              <CopyButton
                className="btn--outline--black"
                value={<>Bel met {fields?.relation?.recap?.firstname}</>}
                hiddenValue={fields?.relation?.recap?.phone}
                mobileHiddenValue={`tel:${fields?.relation?.recap?.phone}`}
                classNameHiddenValue="text-sm"
              />
              <CopyButton
                className="btn--outline--black"
                value={<>Mail met {fields?.relation?.recap?.firstname}</>}
                hiddenValue={fields?.relation?.recap?.email}
                mobileHiddenValue={`mailto:${fields?.relation?.recap?.email}`}
                classNameHiddenValue="text-sm"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
