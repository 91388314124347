import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Post as Props } from '~/graphql/types'

interface PostProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  data: Props
  type?: 'normal' | 'dark'
  showReadtime?: boolean
  showCategories?: boolean
}

export default function Post({ data, className, type = 'normal', showReadtime = true, showCategories = true, ...rest }: PostProps) {
  if (!data?.title) return null

  return (
    <LosseLink
      to={data.uri}
      className={clsx(
        type === 'normal' && 'bg-white hover:bg-site-base block--shadow',
        type === 'dark' && 'bg-site-base hover:bg-site-grey-alternative',
        'flex h-full w-full py-3 px-4 md:py-5 md:px-6 gap-4 md:gap-6 xl:gap-8 group transition-colors',
        className
      )}
      {...rest}
    >
      <div className="flex items-center">
        <LossePlaatjie
          src={data.featuredImage?.node}
          className="min-w-[100px] min-h-[100px] max-w-[100px] max-h-[100px] md:min-w-[140px] md:min-h-[140px] md:max-w-[140px] md:max-h-[140px] object-cover"
          maxwidth={400}
          title={data?.title}
        />
      </div>
      <div className="flex flex-col justify-between py-4">
        <div className="flex items-center gap-6">
          <div className="flex flex-wrap gap-2 mb-2 md:mb-4">
            {showCategories && data?.categories?.edges?.length > 0 && (
              <>
                {data?.categories?.edges?.map((edge) => (
                  <span
                    key={edge?.node?.databaseId}
                    className="rounded-full bg-site-accent text-xs font-light text-white px-3 md:px-4 py-[2px] md:py-[6px]"
                  >
                    {edge?.node?.name}
                  </span>
                ))}
              </>
            )}
            {showReadtime && data?.recap?.readtime && (
              <div
                className={clsx(
                  type === 'normal' && 'group-hover:text-white',
                  type === 'dark' && 'text-white',
                  'flex items-center gap-2 max-md:text-sm'
                )}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                  <g clipPath="url(#clip0_1652_4247)">
                    <path
                      d="M10.41 0.977905C8.48029 0.977905 6.59395 1.55012 4.98947 2.6222C3.385 3.69427 2.13446 5.21805 1.39601 7.00085C0.657548 8.78364 0.464333 10.7454 0.840796 12.638C1.21726 14.5306 2.14649 16.2691 3.51098 17.6336C4.87547 18.998 6.61394 19.9273 8.50655 20.3037C10.3992 20.6802 12.3609 20.487 14.1437 19.7485C15.9265 19.0101 17.4503 17.7595 18.5223 16.1551C19.5944 14.5506 20.1666 12.6642 20.1666 10.7346C20.1638 8.14779 19.135 5.66777 17.3059 3.83865C15.4768 2.00953 12.9967 0.980703 10.41 0.977905V0.977905ZM10.41 18.8651C8.80191 18.8651 7.22995 18.3883 5.89289 17.4949C4.55583 16.6015 3.51372 15.3316 2.89833 13.846C2.28295 12.3603 2.12194 10.7255 2.43566 9.14837C2.74938 7.5712 3.52374 6.12247 4.66081 4.9854C5.79789 3.84832 7.24662 3.07396 8.82379 2.76024C10.401 2.44652 12.0357 2.60753 13.5214 3.22292C15.0071 3.8383 16.2769 4.88041 17.1703 6.21747C18.0637 7.55453 18.5405 9.12649 18.5405 10.7346C18.5382 12.8902 17.6808 14.9568 16.1565 16.4811C14.6323 18.0054 12.5656 18.8627 10.41 18.8651V18.8651Z"
                      fill="currentColor"
                    />
                    <path
                      d="M10.4097 5.85632C10.194 5.85632 9.98722 5.94198 9.83474 6.09446C9.68226 6.24694 9.5966 6.45374 9.5966 6.66938V10.1858L6.85579 11.903C6.6725 12.0175 6.54221 12.2001 6.49357 12.4107C6.44493 12.6213 6.48193 12.8425 6.59643 13.0258C6.71093 13.2091 6.89356 13.3394 7.10413 13.3881C7.3147 13.4367 7.53597 13.3997 7.71926 13.2852L10.8414 11.3339C10.9593 11.26 11.0564 11.157 11.1231 11.0348C11.1899 10.9127 11.2242 10.7755 11.2227 10.6363V6.66938C11.2227 6.45374 11.137 6.24694 10.9846 6.09446C10.8321 5.94198 10.6253 5.85632 10.4097 5.85632Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
                {data?.recap?.readtime} min.
              </div>
            )}
          </div>
        </div>
        <h3 className={clsx(type === 'normal' && 'group-hover:text-white', type === 'dark' && 'text-white', 'line-clamp-3 title--light')}>
          {data?.title}
        </h3>
      </div>
    </LosseLink>
  )
}
